<template>
  <div class="outbox">
    <div class="item">
      <h1 class="title">经典案例</h1>
      <div class="content">
        <ul class="section">
          <li v-for="(item,index) in navList" :key="item" :class="index==liIndex?'active':''" @click="chooseNav(index)">{{ item }}
          </li>
        </ul>
         <div class="index_swiper" v-if="loadingShow">
           <swiper ref="mySwiper" :options="optionPics" style="width: 100%; height: 700px">
             <swiper-slide class="poster_item swiper-no-swiping" v-for="(item,index) in navListImg[liIndex].list" :key="index">
               <div class="pics_outbox">
                 <div class="pics_item" v-for="(it,idx) in item.listImg" :key="idx">
                   <img class="nexBtn" :src="it.src" v-lazy>
                   <span class="pics_title">{{ it.title }}</span>
                 </div>
               </div>
             </swiper-slide>
           </swiper>
         </div>
      </div>
    </div>
    <div class="swiper-pagination small_point"></div>
    <div class="nextEl" :class="liIndex==4?'active_nextEl':''"></div>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: 'index_content',
  components:{
    Swiper,
    SwiperSlide,
  },
  data() {
    return ({
      navList: ['电商平台', '智慧教培', '智慧制造', '益智游戏', '其他'],
      liIndex: 0,
      navListImg: [
        {
          list: [
            {
              listImg:[
                {
                  src: require('@/static/index/index_content/dianshang/1.png'),
                  title: '分销商城'
                },
                {
                  src: require('@/static/index/index_content/dianshang/2.png'),
                  title: '产品批发电商平台'
                },
                {
                  src: require('@/static/index/index_content/dianshang/3.png'),
                  title: '电商购物商城'
                }, {
                  src: require('@/static/index/index_content/dianshang/4.png'),
                  title: '剧本商城展会系统'
                }, {
                  src: require('@/static/index/index_content/dianshang/5.png'),
                  title: '直销商城'
                }, {
                  src: require('@/static/index/index_content/dianshang/6.png'),
                  title: '买家秀定制'
                },
              ]
            },
          ]
        },
        {
          list: [
            {
              listImg:[
                {
                  src: require('@/static/index/index_content/educate/1.png'),
                  title: '干部教育'
                },
                {
                  src: require('@/static/index/index_content/educate/2.png'),
                  title: '职业技能培训'
                },
                {
                  src: require('@/static/index/index_content/educate/3.png'),
                  title: '网络教育训练学院'
                }, {
                  src: require('@/static/index/index_content/educate/4.png'),
                  title: '兴趣教育培训系统'
                }, {
                  src: require('@/static/index/index_content/educate/5.png'),
                  title: '学生安全管理平台'
                }, {
                  src: require('@/static/index/index_content/educate/6.png'),
                  title: '智慧教育培训平台'
                },
              ]
            }
          ]
        },
        {
          list: [
            {
              listImg: [{
                src: require('@/static/index/index_content/produce/1.png'),
                title: '智慧制造APP'
              },
                {
                  src: require('@/static/index/index_content/produce/2.png'),
                  title: '流程管理系统'
                },
                {
                  src: require('@/static/index/index_content/produce/3.png'),
                  title: '可视化数据大屏'
                }, {
                  src: require('@/static/index/index_content/produce/4.png'),
                  title: '数据可视化平台'
                }, {
                  src: require('@/static/index/index_content/produce/5.png'),
                  title: '制造业app管理系统'
                }, {
                  src: require('@/static/index/index_content/produce/6.png'),
                  title: '多端可视化系统'
                }]
            }
          ]
        },
        {
          list: [
            {
              listImg: [
                  {
                src: require('@/static/index/index_content/game/1.png'),
                title: '游戏网页平台'
              },
                {
                  src: require('@/static/index/index_content/game/2.png'),
                  title: '游戏商城app'
                },
                {
                  src: require('@/static/index/index_content/game/3.png'),
                  title: '游戏商城app页面设计'
                }, {
                  src: require('@/static/index/index_content/game/4.png'),
                  title: '游戏拼图网站'
                }, {
                  src: require('@/static/index/index_content/game/5.png'),
                  title: '游戏社区网站'
                }, {
                  src: require('@/static/index/index_content/game/6.png'),
                  title: '游戏商城界面'
                },
              ]
            },
          ]
        },
        {
          list: [
            {
              listImg: [
                {
                  src: require('@/static/index/index_content/other/gym/1.png'),
                  title: '运动商城网站'
                },
                {
                  src: require('@/static/index/index_content/other/gym/2.png'),
                  title: '健身器材分享APP'
                },
                {
                  src: require('@/static/index/index_content/other/gym/3.png'),
                  title: '运动商城app'
                }, {
                  src: require('@/static/index/index_content/other/gym/4.png'),
                  title: '健身房网站平台'
                }, {
                  src: require('@/static/index/index_content/other/gym/5.png'),
                  title: '健身房app首页'
                }, {
                  src: require('@/static/index/index_content/other/gym/6.png'),
                  title: '登山运动网页'
                },
              ]
            },
            {
              listImg: [
                {
                  src: require('@/static/index/index_content/other/insurance/1.png'),
                  title: '平安保险'
                },
                {
                  src: require('@/static/index/index_content/other/insurance/2.png'),
                  title: '尊享未来'
                },
                {
                  src: require('@/static/index/index_content/other/insurance/3.png'),
                  title: '保险APP首页'
                }, {
                  src: require('@/static/index/index_content/other/insurance/4.png'),
                  title: '保险后台首页'
                }, {
                  src: require('@/static/index/index_content/other/insurance/5.png'),
                  title: '保险自动化办公系统'
                }, {
                  src: require('@/static/index/index_content/other/insurance/6.png'),
                  title: '保险分销商城'
                },
              ]
            },
            {
              listImg: [
                {
                  src: require('@/static/index/index_content/other/shopping/1.png'),
                  title: '超市购物平台（社区型）'
                },
                {
                  src: require('@/static/index/index_content/other/shopping/2.png'),
                  title: '设计网页平台网站'
                },
                {
                  src: require('@/static/index/index_content/other/shopping/3.png'),
                  title: '笛粒购物网站'
                }, {
                  src: require('@/static/index/index_content/other/shopping/4.png'),
                  title: '购物便捷化（小型社区）'
                }, {
                  src: require('@/static/index/index_content/other/shopping/5.png'),
                  title: '购物直销商城'
                }, {
                  src: require('@/static/index/index_content/other/shopping/6.png'),
                  title: '惜木艾草网站'
                },
              ]
            },
            {
              listImg: [
                {
                  src: require('@/static/index/index_content/other/OA/1.png'),
                  title: 'OA流程系统首页'
                },
                {
                  src: require('@/static/index/index_content/other/OA/2.png'),
                  title: 'OA自动化办公系统'
                },
                {
                  src: require('@/static/index/index_content/other/OA/3.png'),
                  title: 'OA办公APP首页'
                }, {
                  src: require('@/static/index/index_content/other/OA/4.png'),
                  title: 'OA网站平台'
                }, {
                  src: require('@/static/index/index_content/other/OA/5.png'),
                  title: '超市OA自动化办公（手机端）'
                }, {
                  src: require('@/static/index/index_content/other/OA/6.png'),
                  title: 'OA系统后台展示'
                },
              ]
            },
            {
              listImg: [
                {
                  src: require('@/static/index/index_content/other/manage_wuzi/1.png'),
                  title: '物资管理定制系统'
                },
                {
                  src: require('@/static/index/index_content/other/manage_wuzi/2.png'),
                  title: '物资多端管理系统'
                },
                {
                  src: require('@/static/index/index_content/other/manage_wuzi/3.png'),
                  title: '物资管理APP'
                }, {
                  src: require('@/static/index/index_content/other/manage_wuzi/4.png'),
                  title: '物资管理app首页展示'
                }, {
                  src: require('@/static/index/index_content/other/manage_wuzi/5.png'),
                  title: '物资管理可视化平台'
                }, {
                  src: require('@/static/index/index_content/other/manage_wuzi/6.png'),
                  title: '物资展览系统'
                },
              ]
            },
            {
              listImg: [
                {
                  src: require('@/static/index/index_content/other/trip/1.png'),
                  title: '时尚旅行网站'
                },
                {
                  src: require('@/static/index/index_content/other/trip/2.png'),
                  title: '旅游分享平台'
                },
                {
                  src: require('@/static/index/index_content/other/trip/3.png'),
                  title: '旅行app首页展示'
                }, {
                  src: require('@/static/index/index_content/other/trip/4.png'),
                  title: '旅途在路上网站首页'
                }, {
                  src: require('@/static/index/index_content/other/trip/5.png'),
                  title: '旅行必备用品分享APP'
                }, {
                  src: require('@/static/index/index_content/other/trip/6.png'),
                  title: '旅行商城展会系统'
                },
              ]
            },
          ]
        },
      ],
      loadCount:0,
      loadingShow:false,
      optionPics: {
        direction: "vertical", //设置竖向轮播
        // speed: 500,
        noSwiping: true,//禁止手动
        loop:false,
        pagination: {
          el: ".small_point",
          type: 'bullets',
          dynamicMainBullets: 2, //动态分页器的主指示点的数ss量
          clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换
        },
        navigation: {
          nextEl: ".nextEl",
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        //   stopOnLastSlide: false,
        //   reverseDirection: false,
        // },

        // on: {
        //   slideChangeTransitionEnd: function () {
        //     // this.activeIndex 是索引index，这个用this才可以获取到
        //     console.log(this.liIndex);
        //   },
        // },
      },
    })
  },
  mounted() {
    this.getImages()
  },
  methods: {
    chooseNav(idx) {
      this.liIndex = idx
    },
    getImages(){
      for (let img of this.navListImg) {
        for(let imgs of img.list){
          for (let images of imgs.listImg){
            let image = new Image()
            image.src = images.src
            image.onload = () => {
              console.log("image.onload:src:"+image.src);
              this.loadCount++
              // 计算图片加载的百分数，绑定到percent变量
              // let percentNum = Math.floor(loadCount.value / this.navListImg.length * 100)
              // loadingProcess.value = percentNum;

              //判断是否结束
              if (this.loadCount >= this.navListImg.length) {
                //loading end
                this.loadingShow = true;
              }
            }
          }
        }
    }
    }
  }
}
</script>

<style lang="scss" scoped>
.outbox {
  position: relative;
  margin-top: 20px;
  padding-top: 28px;
  width: 80%;
  margin: 0 auto;
  height: auto;
  background-color: #f8fafe;

  .item {
    z-index: 2;
    width: 74%;
    margin: 0 auto;
    position: relative;
    padding: 45px 0 10px 0;
    height: 100%;

    .title {
      position: absolute;
      top: 20px;
      left: 0;
      font-size:44px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      //color: #0f559a;
      color: #58A4F8;
      line-height: 1;
    }

    .title::before {
      content: 'PRODUCTS';
      position: absolute;
      top: -30px;
      left: 0;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #edb215;
      line-height: 1;
    }

    .content {
      width: 100%;
      .section {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-right: 32px;
        height: 100px;

        li {
          list-style: none;
          padding: 10px 23px 10px 23px;
          margin-right: 5px;
          border-radius: 40px;
          line-height: 1;
          cursor: pointer;
          font-size: 20px;
          font-weight: 500;
          font-family: Adobe Heiti Std;
          color: #000;
        }
      }

      .pics_outbox {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        //background-color: #000000;

        .pics_item {
          display: flex;
          flex-direction: column;
          width: 31%;
          img {
            width: 100%;
            height: 60%;
          }
          .pics_title{
            padding: 19px;
            background: #f2f4f7;
            font-size: 18px;
            //font-family: Adobe Heiti Std;
            color: #000;
            line-height: 1;
            margin-bottom: 30px;
          }
        }
      }
    }

    .active {
      //background-color: #0f559a;
      background-color: #58A4F8;
      color: #ffffff !important;
    }
  }
}
.nextEl{
  position: absolute;
  right:3.6%;
  top:52%;
  transform: translateX(-50%);
  width:45px;
  height:45px;
  background: url('@/assets/img/down-arrow.png') no-repeat 0 0;
  background-size: 100%;
}
.active_nextEl{
  top:60%;
}
::v-deep .swiper-pagination {
  height: auto;
  padding: 10px 20px;
  position: absolute;
  right:5%;
  top:50%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
}
::v-deep .swiper-pagination-bullet{
  background: #565656 !important;
  margin-bottom: 20px;
  width: 10px;
  height: 10px;
}
::v-deep .swiper-pagination-bullet-active {
  background: #edb215 !important;
}
</style>