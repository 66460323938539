<template>
  <div>
    <div class="index_swiper" @mouseenter="mouseEnter1" @mouseleave="mouseLeave1">
      <swiper ref="mySwiper" :options="swiperOption" style="width: 100%; height: 700px">
        <swiper-slide class="poster_item swiper-no-swiping" v-for="(item, index) in posterObj" :key="index">
          <img :src="item.imgUrl" alt="" style="width: 100%; height: 700px"/>
          <div class="box">
            <div class="text">{{ item.text }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="title2">{{ item.title2 }}</div>
            <div class="title2">{{ item.title3 }}</div>
            <div class="goto" @click="gotoCompanyProduct()">了解详情</div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev swiper-button-white"></div> <!-- 白色 -->
      <div class="swiper-button-next swiper-button-white"></div>
    </div>
    <div class="top_bg">
      <div class="top">
        <div class="top_box">
          <div class="image">
            <img src="@/static/5.png">
          </div>
          <div class="info">
            <div class="text">专属顾问团队</div>
            <div class="title">多对一服务</div>
          </div>
        </div>
        <div class="top_box">
          <div class="image">
            <img src="@/static/6.png">
          </div>
          <div class="info">
            <div class="text">一站式服务</div>
            <div class="title">解决方案</div>
          </div>
        </div>
        <div class="top_box">
          <div class="image">
            <img src="@/static/7.png">
          </div>
          <div class="info">
            <div class="text">技术专家</div>
            <div class="title">高新技术</div>
          </div>
        </div>
        <div class="top_box">
          <div class="image">
            <img src="@/static/8.png">
          </div>
          <div class="info">
            <div class="text">智慧平台</div>
            <div class="title">智慧互联网</div>
          </div>
        </div>
      </div>
    </div>

    <title_components></title_components>

    <index_content></index_content>
    

    <index_grid></index_grid>

    <index_choose></index_choose>

    <createContact ref="contactForm"></createContact>
  </div>
</template>

<script>
import title_components from "@/components/title_components";
import index_content from '@/components/index_content.vue'
import index_grid from '@/components/index_grid.vue'
import index_choose from '@/components/index_choose.vue'
import createContact from "@/components/createContact.vue";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide,
    title_components,
    index_content,
    index_grid,
    index_choose,
    createContact
  },
  data() {
    return {
      posterObj: [
        {
          imgUrl: [require('@/static/banner1.png')],
          text: 'APP、小程序定制化研发',
          title: '满足您的一切个性化需求',
          title2: '致力于为用户创造最高品质的成果'
        },
        {
          imgUrl: [require('@/static/banner2.png')],
          text: '网站个性化研发',
          title: '每一款都是定制的艺术品',
          title2: '快速提高企业形象与品牌价值',
          title3: '一次建站，PC、平板、手机端通通都有'
        },
        {
          imgUrl: [require('@/static/banner3.png')],
          text: '智慧系统解决方案',
          title: '人脸识别、语音生成、语音识别',
          title2: '互联网医院，智慧医院',
          title3: '接入全场景AI，助您实现高质量发展'
        },
      ],
      swiperOption: {
        effect: 'cube',//3d
        direction: 'vertical',//垂直滑动
        speed: 2500,
        noSwiping: true,//禁止手动
        //自动
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          reverseDirection: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicMainBullets: 2, //动态分页器的主指示点的数ss量
          clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换。
        },
      },
    }
  },
  methods: {
    mouseEnter1() {
      this.$refs.mySwiper.$swiper.autoplay.stop()
    },
    mouseLeave1() {
      this.$refs.mySwiper.$swiper.autoplay.start();
    },
    // gotoCompanyProduct() {
    //   this.$router.push({ name: 'companyProduct' })
    // }
    gotoCompanyProduct() {
      this.$refs.contactForm.form = {
        companyName: '',
        name: '',
        phone: '',
        phoneCode: '',
        remark: '',
      }
      this.$refs.contactForm.dialogFormVisible = true;
    }
  }

}
</script>

<style scoped lang="scss">
.index_swiper {
  overflow: hidden;

  .swiper-pagination {
    height: 20px;
    padding: 10px 20px;
    position: absolute;
    margin-top: -40px;
    left: 50%;
    z-index: 99;
    transform: translateX(-50%);

    ::v-deep .swiper-pagination-bullet {
      margin-left: 10px;
      margin-right: 10px;
      border: 1px solid black !important;
      background: rgb(186, 189, 200);
    }

    ::v-deep .swiper-pagination-bullet-active {
      background: white !important;
    }
  }

  .swiper-button-next {
    width: 32px;
    height: 100px;
    right: 100px;
  }

  .swiper-button-prev {
    width: 32px;
    height: 100px;
    left: 100px;
  }

  .box {
    position: absolute;
    z-index: 9999;
    color: white;
    top: 200px;
    left: 360px;

    .text {
      font-weight: bold;
      font-size: 60px;
    }

    .title,
    .title2 {
      margin-top: 10px;
      font-size: 20px;
    }

    .goto {
      cursor: pointer;
      width: 120px;
      font-size: 20px;
      text-align: center;
      margin-top: 40px;
      padding: 14px 50px;
      border: 1px solid rgb(0, 160, 233);
      border-radius: 6px;
    }
  }
}

.top_bg {
  //background-color: #58A4F8;
  .top {
    width: 1200px;
    margin: 0 auto;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding-right: 80px;

    .top_box {
      flex: 1;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      .image {
        img {
          width: 60px;
        }
      }

      .info {
        margin-left: 20px;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;

        .text {
          // width: 180px;
          font-size: 19px;
          margin-bottom: 6px;
          color: #000000;
        }

        .title {
          font-size: 14px;
          color: #323232;
        }
      }
    }

  }
}</style>
