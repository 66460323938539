<template>
  <div class="index_width">
    <div class="index_choose">
      <div class="choose_top">
        <div class="choose_top_title">
          服务流程
        </div>
        <div class="choose_top_msg">
          专业严谨的项目研发管理流程，让您满意是我们的追求
        </div>
      </div>
      <!-- <div class="choose_main">
        <div class="choose_main_item">
          <div class="choose_main_item_num">10</div>年行业网经验服务
        </div>
        <div class="choose_main_item">
          <div class="choose_main_item_num">12</div>家分公司
        </div>
        <div class="choose_main_item">
          <div class="choose_main_item_num">101</div>万行业客户
        </div>
      </div> -->

      <div class="choose_new_content">
        <div class="choose_item">
          <div class="image">
            <img src="../static/index/step_01.png" alt="">
          </div>
          <div class="choose_item_title">01 需求调研</div>
          <div class="choose_item_msg">收集需求、定位分析、可行性分析</div>
        </div>
        <div class="choose_item">
           <div class="image">
            <img src="../static/index/step_02.png" alt="">
          </div>
          <div class="choose_item_title">02 方案规划</div>
          <div class="choose_item_msg">功能策划、风格设计、内容规划、客户沟通、合同签订</div>
        </div>
        <div class="choose_item">
          <div class="image">
            <img src="../static/index/step_03.png" alt="">
          </div>
          <div class="choose_item_title">03 项目立项</div>
          <div class="choose_item_msg">项目启动，成立专项小组流程分析，原型图制作、确定工时、项目管理</div>
        </div>
        <div class="choose_item">
           <div class="image">
            <img src="../static/index/step_04.png" alt="">
          </div>
          <div class="choose_item_title">04 视觉设计</div>
          <div class="choose_item_msg">创意设计、页面设计、用户体验研究</div>
        </div>
        <div class="choose_item">
           <div class="image">
            <img src="../static/index/step_05.png" alt="" style="width: 60%!important;height: 60%!important;">
          </div>
          <div class="choose_item_title">08 后期维护</div>
          <div class="choose_item_msg">系统设计、程序开发、多平台开发</div>
        </div>
        <div class="choose_item">
          <div class="image">
            <img src="../static/index/step_06.png" alt="">
          </div>
          <div class="choose_item_title">07 验收上线</div>
          <div class="choose_item_msg">验收合格，运营上线</div>
        </div>
        <div class="choose_item">
           <div class="image">
            <img src="../static/index/step_07.png" alt="">
          </div>
          <div class="choose_item_title">06 集成测试</div>
          <div class="choose_item_msg">验收测试，客户意见</div>
        </div>
        <div class="choose_item">
          <div class="image">
            <img src="../static/index/step_08.png" alt="">
          </div>
          <div class="choose_item_title">05 程序开发</div>
          <div class="choose_item_msg">系统设计、程序开发、多平台开发</div>
        </div>
      </div>
      <div class="contactUs" @click="contactUs()">联系我们</div>
    </div>
    <createContact ref="contactForm"></createContact>
  </div>
</template>

<script>
import createContact from "@/components/createContact.vue";
export default {
  name: 'index_choose',
  components:{createContact},
  data() {
    return ({
    })
  },
  methods: {
    contactUs() {
      this.$refs.contactForm.form = {
        companyName: '',
        name: '',
        phone: '',
        phoneCode: '',
        remark: '',
        id:0
      }
      this.$refs.contactForm.dialogFormVisible = true;
    }
  },
}
</script>

<style lang="scss" scoped>
.index_width {
  //background: url('@/static/index/bg2.jpg');
  //background-size: 100% 100%;
  background-color: #F8F8F8;
  .contactUs{
    width: 140px;
    height: 45px;
    display: block;
    background: #58A4F8;
    text-align: center;
    line-height: 45px;
    color: #fff;
    font-size: 16px;
    border-radius: 3px;
    margin: 0 auto;
    cursor: pointer;
    letter-spacing: 1px;
  }

  .index_choose {
    padding:30px 0;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .choose_top {
     text-align: center;
      .choose_top_title {
        font-size: 28px;
        margin-bottom: 20px;
        color: #000;
      }

      .choose_top_msg {
        font-size: 12px;
        color: #666666;
      }
    }

    .choose_new_content {
      // height: 605px;
      margin-left: 120px;
      margin-top: 50px;
      background: url(../static/index/point_03.png) no-repeat;
      background-position: 100px 50px;
      display: flex;
      flex-wrap: wrap;

      .choose_item{
        text-align: center;
        width: 150px;
        height: 230px;
        margin-right: 110px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .image{
          width: 105px;
          height: 105px;
          background-color: #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 50%;
            height:50%;
            line-height: 110px;
          }
        }
        .choose_item_title{
          font-size: 14px;
          color: #000;
          margin: 20px 0 5px 0;
        }
        .choose_item_msg{
          color: #636363;
          // color: #636363;
          font-size: 12px;
        }
      }
    }
    // .choose_main {
    //   display: flex;
    //   justify-content: space-between;
    //   margin-bottom: 50px;

    //   .choose_main_item {
    //     color: rgb(0, 160, 233);
    //     display: flex;
    //     vertical-align: bottom;
    //     display: flex;
    //     align-items: flex-end;
    //     font-size: 18px;

    //     .choose_main_item_num {
    //       font-weight: 700;
    //       font-size: 100px;
    //       line-height: 100px;
    //       margin-right: 20px;
    //     }
    //   }
    // }
  }
}
</style>