import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: { 
      title:'首页'
     }
  },
  {
    path: '/companyProduct',
    name: 'companyProduct',
    component: () => import('../views/companyProduct.vue'),
    meta: {
      title:'公司产品'
    }
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/solution.vue')
  },
  {
    path: '/informationCenter',
    name: 'informationCenter',
    component: () => import('../views/informationCenter.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () =>import('../views/contactUs')
  },{
    path:'/infomationDetail',
    name:'infomationDetail',
    component: () => import('@/views/infomation/infomationDetail.vue')
  },{
    path:'/login',
    name:'login',
    component: () => import('@/views/login.vue')
  },{
    path:'/register',
    name:'register',
    component: () => import('@/views/register.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 解决vue router打开新页面时会跳转到新页面底部
  scrollBehavior() {
    return { x: 0,y: 0 };
  }
})

// router.beforeEach((to,form,next)=>{
//   document.title = to.meta.title || 'Y188'
//   next()
// })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
