<template>
  <div>
    <div class="box">
      <div class="wrapper">
        <div class="left" @click="$router.push('/')">顶卫云科技</div>
      <div class="middle">
        <div class="father_box" v-for="(item,index) in title" :key="index" @mouseleave="removeActive()">
          <div class="choose" :class="menuIndex == index || mouseover ==index ? 'active' : ''"
             @click="selectMenu(item.father,index)"
             @mouseover="getMouseover(index)"
            >{{item.father}}
          </div>
          <div class="father_contact_us" v-if="item.child.length != 0 && mouseover ==index">
            <div class="triangle"></div>
            <div class="father_contact_us_text">
              <div class="contact_us" :class="mouseoverChild == indexs ? 'active2' : ''" v-for="(items,indexs) in item.child"
                   @mouseover="getMouseoverChild(indexs)"
                   @click="selectMenu(items.first,index)">
                {{items.first}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
<!--        <div class="login" @click="$router.push('/login')">登录</div>-->
<!--        <div class="register" @click="$router.push('/register')">注册</div>-->
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header_menu",
  data(){
    return{
      //添加tab栏时请添加监听事件，否则标识无法定位
      title:[
        {father:'首页',child:[]},
        // {father:'公司产品',child:[]},
        {father:'解决方案',child:[]},
        {father:'资讯中心',child:[]},
        {father:'关于我们',child:[]},
        {father:'联系我们',child:[]},
        // {father:'关于我们',child:[{first:'联系我们'}]}
      ],
      menuIndex:0,
      mouseover:-1,
      mouseoverChild:-1,
    }
  },
  watch:{
    $route:{
      handler(val,oldVal){
        let index;
        if(val.path=='/'){
          index = 0
        }else if(val.path=='/solution'){
          index = 1
        }else if(val.path=='/informationCenter'){
          index = 2
        }else if(val.path=='/aboutUs'){
          index = 3
        }else if(val.path=='/contactUs'){
          index = 4
        }
        this.selectMenu('',index)
      },
      deep:true
    }
  },
  methods:{
    selectMenu(item,index){
      this.menuIndex = index
      if(item=='首页'){
        this.$router.push({ name: 'index'});
      }else if(item=='公司产品'){
        this.$router.push({ name: 'companyProduct'});
      }else if(item=='解决方案'){
        this.$router.push({ name: 'solution' });
      }else if(item=='资讯中心'){
        this.$router.push({ name: 'informationCenter'});
      }else if(item=='关于我们'){
        this.$router.push({ name: 'aboutUs' });
      }else if(item=='联系我们'){
        this.$router.push({ name: 'contactUs'});
      }
    },
    getMouseover(index){
      this.mouseover = index
    },
    getMouseoverChild(indexs){
      this.mouseoverChild = indexs
    },
    removeActive(){
      this.mouseover = -1;
      this.mouseoverChild = -1
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  height: 120px;
  background-color: #ffffff;
  color: #000000;
  //background: linear-gradient(to right, #fbc2eb, #a6c1ee);
  //background: -moz-linear-gradient(45deg,  #2058ab 0%, #27539c 17%,
  //    #3a4a7b 46%, #3a4a7b 46%, #494367 56%,
  //    #543c52 92%, #442541 100%); /* FF3.6+ */
  //background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,#2058ab), color-stop(17%,#27539c), color-stop(46%,#3a4a7b), color-stop(46%,#3a4a7b), color-stop(56%,#3a4a7b), color-stop(92%,#27539c), color-stop(100%,#442541)); /* Chrome,Safari4+ */
  //background: -webkit-linear-gradient(45deg,  #2058ab 0%,#27539c 17%,#3a4a7b 46%,#3a4a7b 46%,#494367 56%,#543c52 92%,#442541 100%); /* Chrome10+,Safari5.1+ */
  //background: -o-linear-gradient(45deg,  #2058ab 0%,#27539c 17%,#3a4a7b 46%,#3a4a7b 46%,#494367 56%,#543c52 92%,#442541 100%); /* Opera 11.10+ */
  //background: -ms-linear-gradient(45deg,  #2058ab 0%,#27539c 17%,#3a4a7b 46%,#3a4a7b 46%,#494367 56%,#543c52 92%,#442541 100%); /* IE10+ */
  //background: linear-gradient(45deg,  #2058ab 0%,#27539c 17%,#3a4a7b 46%,#3a4a7b 46%,#494367 56%,#543c52 92%,#442541 100%); /* W3C */
  
  .wrapper{
    width: 1200px;
    height: 120px;
    display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  }
  .left{
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    width: 200px;
    
  }
  .middle{
    flex-shrink: 0;
    width: 800px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    height: 50px;
    margin-top: 10px;
    .choose{
      padding: 10px 30px;
      flex-shrink: 0;
    }
    .active{
      cursor: pointer;
      //background-color: white;
      //color: rgb(0,159,233);
      background-color: #4f9bf0;
      color: #ffffff;
      border-radius: 20px;
    }
    .father_contact_us{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 2px;
      position: absolute;
      z-index: 99999;
      .triangle{
        width:0px;
        height:0px;
        border:6px solid transparent;
        border-bottom-color:white;
      }
      .father_contact_us_text{
        border-radius: 10px;
        background-color: white;
        border: 1px solid #605f5f;
        color: black;
        .contact_us{
          font-size: 18px;
          cursor: pointer;
          padding: 12px 30px;
          position: relative;
          border-bottom: 1px solid gray;
        }
        .contact_us:last-child{
          border: none;
        }
        .active2{
          color: rgb(0,159,233);
        }
      }

    }

  }
  .right{
    display: flex;
    flex-shrink: 0;
    .login{
      border: 1px solid #323232;
    }
    .register,.login{
      //border: 1px solid cornflowerblue;
      padding: 10px 30px;
      border-radius: 20px;
      margin-right: 40px;
      cursor: pointer;
    }
    .register{
      cursor: pointer;
      color: #ffffff;
      background-color: #378ac3;
      //background-color: #434343;
    }
  }

}
</style>
