<template>
 <div>
   <el-dialog
       title="预约一对一服务"
       :before-close="handleClose"
       :visible.sync="dialogFormVisible"
       :modal-append-to-body="false"
   >
     <el-form :model="form" label-width="150px" :rules="rules" ref="form">
       <el-form-item label="公司名字：">
         <el-input clearable placeholder="请输入公司名字" v-model="form.companyName" autocomplete="off"></el-input>
       </el-form-item>
       <el-form-item label="姓名：" prop="name">
         <el-input clearable placeholder="请输入姓名" v-model="form.name" autocomplete="off"></el-input>
       </el-form-item>
       <el-form-item label="手机号码：" prop="phone">
         <el-input clearable placeholder="请输入手机号码" v-model="form.phone" style="width: 80%!important;" autocomplete="off"></el-input>
         <el-button style="font-size: 12px;margin-left: 12px;float: right"
                    @click="getPhoneCode"
                    type="primary"
                    plain
                    :disabled="isCode">{{ codeName }}
         </el-button>
       </el-form-item>
       <el-form-item label="手机号验证码：" prop="phoneCode">
         <el-input
             v-model="form.phoneCode"
             placeholder="请输入手机验证码"
         >
         </el-input>
       </el-form-item>
       <el-form-item label="描述：">
         <el-input clearable placeholder="请输入描述内容" v-model="form.remark" autocomplete="off" type="textarea" ></el-input>
       </el-form-item>
     </el-form>
     <div slot="footer" class="dialog-footer">
       <el-button type="primary" @click="sendForm">确 定</el-button>
     </div>
   </el-dialog>
   <!--  弹出验证 -->
   <el-dialog title="操作验证" :visible.sync="dialogTableVisible" :modal-append-to-body="false" top="20vh" style="width: 60%;margin: 0 auto">
     <el-form :model="codeForm" @submit.native.prevent>
       <el-form-item prop="imageCode" v-if="captchaEnabled">
         <el-input
             v-model="codeForm.imageCode"
             auto-complete="off"
             placeholder="验证码"
             style="width:60%"
             @keyup.enter.native="sendCode"
         >
         </el-input>
         <div class="register-code">
           <img :src="codePhoneUrl" @click="getCode()" class="register-code-img"/>
         </div>
       </el-form-item>
     </el-form>
     <div slot="footer" class="dialog-footer">
       <el-button type="primary" @click="sendCode">确 定</el-button>
     </div>
   </el-dialog>
 </div>
</template>

<script>
import {getCodeImg, infoCollectFn,phoneCode} from "@/api/contactUs";
import bus from "@/components/eventBus";
export default {
  name: "createContact",
  data() {
    return ({
      dialogFormVisible:false,
      dialogTableVisible:false,
      form:{},
      codeForm:{
        phone:'',
        imageCode:'',
        uuid:''
      },
      codePhoneUrl:'',
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }
        ],
        phoneCode: [
          { required: true, message: '请输入验证码', trigger: 'change' },
          { min: 4, max: 6, message: '请输入4-6个数字的验证码', trigger: 'blur' }
        ],
      },
      captchaEnabled: true,
      codeName:'获取验证码',
      waitTime:60, // 获取验证码按钮失效时间
      isCode:false,
    })
  },
  methods:{
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    getPhoneCode(){
      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
      if(!reg.test(this.form.phone)){
        this.$message.warning('请输入正确的手机号');
        return
      }
      this.dialogTableVisible = true;
      this.getCode();
    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codePhoneUrl = "data:image/gif;base64," + res.img;
          this.codeForm.uuid = res.uuid;
        }
      });
    },
    // 获取验证码
    async sendCode(){
      if(this.codeForm.imageCode==''){
        this.$message.warning('请输入验证码');
        return
      }
      let data = {
        phone:this.form.phone,
        imageCode:this.codeForm.imageCode,
        uuid:this.codeForm.uuid,
      }
      const res = await phoneCode(data)
      this.dialogTableVisible = false;
      this.codeForm.imageCode = '';
      this.$message.success('验证码发送成功')
      let that = this;
      that.waitTime--;
      that.isCode = true;
      this.codeName = `${this.waitTime}s 后重新发送`;
      let timer = setInterval(function () {
        if (that.waitTime > 1) {
          that.waitTime--;
          that.codeName = `${that.waitTime}s 后重新发送`;
        } else {
          clearInterval(timer)
          that.codeName = '获取验证码';
          that.isCode = false;
          that.waitTime = 60;
        }
      }, 1000)
    },

    // 提交表单信息
    sendForm(){
      this.$refs.form.validate(valid => {
        if (valid) {
          infoCollectFn(this.form).then(res=>{
            this.$message.success('预约成功，请耐心等待工作人员的联系');
            this.dialogFormVisible = false;
          }).catch(err=>{
          })
        }else{
        }
      })
    }
  },
  // created() {
  //   bus.$on('createContact',(val)=>{
  //     console.log(val,'---val---')
  //     this.form = val;
  //     this.dialogFormVisible = true
  //   })
  // }
}
</script>

<style lang="scss" scoped>
.register-code {
  width: 30%;
  height: 48px;
  float: right;
img {
  width: 100%;
  height:100%;
  cursor: pointer;
  vertical-align: middle;
}
}

</style>