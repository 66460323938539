<template>
  <div class="id">
    <div class="text">重要合作伙伴</div>
    <div class="title">专业的技术团队与强大的合作伙伴助力企业高质量发展</div>
    <div class="middle">
      <div class="box">
        <img src="../static/albb.jpg">
        <div>阿里云</div>
      </div>
      <div class="box">
        <img src="../static/tengxun.jpg">
        <div>腾讯云</div>
      </div>
      <div class="box">
        <img style="width: 200px!important;" src="../static/azure.jpeg">
        <div>微软云</div>
      </div>
      <div class="box">
        <img src="../static/liantong.jpg">
        <div>联通云</div>
      </div>
      <div class="box">
        <img src="../static/huawei.jpg">
        <div>华为云</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "title_components"
}
</script>

<style scoped lang="scss">
.id{
  // box-sizing: border-box;
  // width: 100%;
  width: 1200px;
  height: 494px;
  background: white;
  padding: 100px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  box-sizing: border-box;
  .text{
    font-size: 32px;
  }
  .title{
    margin-top: 4px;
    font-size: 14px;
    color: rgb(182,161,161);
  }
  .middle{
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    .box{
      display: flex;
      align-items: center;
      flex-direction: column;
      img{
        width:150px;
        height:120px;
      }
      div{
        margin-top: 5px;
        font-size: 16px;
      }
    }
  }
}
</style>
