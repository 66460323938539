<template>
  <div class="index_width">
    <div class="index_grid">
    <div class="grid_list">
      <div class="grid_item" v-for="(item,index) in gridList" :key="index">
        <img :src="item.imgUrl" alt="">
        <div class="grid_item_title">
          {{ item.title }}   
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default{
  name:'index_grid',
  data(){
    return({
      gridList:[
        {
          imgUrl:require('@/static/index/grid1.png'),
          title:'智慧共享'
        },{
          imgUrl:require('@/static/index/grid2.png'),
          title:'智慧场馆'
        },{
          imgUrl:require('@/static/index/grid3.png'),
          title:'智慧园区'
        },{
          imgUrl:require('@/static/index/grid4.png'),
          title:'智慧出行'
        },{
          imgUrl:require('@/static/index/grid5.png'),
          title:'智慧市政'
        },{
          imgUrl:require('@/static/index/grid6.png'),
          title:'智能制造'
        },{
          imgUrl:require('@/static/index/grid7.png'),
          title:'智慧农业'
        },{
          imgUrl:require('@/static/index/grid8.png'),
          title:'智慧医疗'
        }
      ]
    })
  }
}
</script>

<style lang="scss" scoped>
.index_width{
  padding: 50px 0;
  *{
    margin: 0 auto;
  }
  .index_grid{
    width: 1200px;
    margin: 0 auto;
  // padding: 80px 20px;
  color: #666666;
  
  .grid_list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .grid_item{
      font-size: 18px;
      width: 24%;
      height: 250px;
      border-right: thin solid rgb(241, 236, 236);
      border-bottom: thin solid rgb(241, 236, 236);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #666666;
      img{
        width: 56px;
        height: 56px;
      }
      .grid_item_title{
        margin-top: 20px;
      }
    }
    .grid_item:nth-child(n+5){
      border-bottom: none;
    }
    .grid_item:nth-child(4n){
      border-right: none;
    }
  }
}
}

</style>