<template>
  <div>
    <div class="wrap_width">
      <div class="top">
      <div class="box">
        <div class="image">
          <img src="@/static/2.png">
        </div>
        <div class="info">
          <div class="text">100%开源代码</div>
          <!-- <div class="title">全国7×24小时客服热线</div> -->
        </div>
      </div>
      <div class="box">
        <div class="image">
          <img src="@/static/1.png">
        </div>
        <div class="info">
          <div class="text">完全掌握项目主权</div>
          <!-- <div class="title">5倍赔付终端服务的时间</div> -->
        </div>
      </div>
      <div class="box">
        <div class="image">
          <img src="@/static/3.png">
        </div>
        <div class="info">
          <div class="text">多对一团队服务</div>
          <!-- <div class="title"> 部分产品可享受15天无理由退款</div> -->
        </div>
      </div>
      <div class="box">
        <div class="image">
          <img src="@/static/4.png">
        </div>
        <div class="info">
          <div class="text">专属顾问团队</div>
          <!-- <div class="title">全国80家分公司便捷服务</div> -->
        </div>
      </div>
    </div>
    </div>
    <div class="bottom">
      <div class="border">
        <div class="box">
          <div class="image">
            <img src="@/static/phone.png">
          </div>
          <div class="info">
            <div class="text">客服咨询</div>
            <div class="title">0731-8582-1080</div>
          </div>
        </div>
      </div>
      <div class="border">
        <div>公司地址：长沙市雨花区金海路158号</div>
      </div>
    <div class="border">
      <div class="finally">
        <div style="margin-bottom: 8px">邮政编码：410007</div>
        <div>客服邮箱：dwychina@163.com</div>
      </div>
    </div>
    </div>
    <div class="filingInfo">
<!--      湖南岳泰兴医疗器械有限公司 <span class="filingNum">湘ICP备19022900号</span>-->
      湖南顶卫云科技有限公司 <span class="filingNum">湘ICP备2023005632号</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "tail_menu"
}
</script>

<style scoped lang="scss">
.wrap_width{
  background-color: #58A4F8;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding-right: 80px;
}
.top{
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
  // background-color: black;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding-right: 80px;
  .box{
    margin-left: 80px;
    color: white;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .image{
      img{
        width: 60px;
      }
    }
    .info{
      margin-left: 20px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      .text{
        font-size: 18px;
        margin-bottom: 6px;
      }
      .title{
        font-size: 14px;
        color: rgb(156, 156, 156);
      }
    }
  }

}
.bottom{
  background-color: rgb(36,38,42);
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: rgb(156, 156, 156);
  .border{
    padding-right: 120px;
    padding-left: 110px;
    border-right: 1px solid rgb(49,49,49);
    height: 100px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  .box{
    color: white;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: rgb(156, 156, 156);
    .image{
      img{
        width: 80px;
      }
    }
    .info{
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      .text{
        font-size: 18px;
        margin-bottom: 6px;
      }
      .title{
        font-size: 30px;
      }
    }
  }
}

.filingInfo{
  background: rgb(36,38,42);
  text-align: center;
  padding-bottom: 20px;
  color: #fff;
  font-size: 12px;
  .filingNum{
    color: orange;
  }
}
</style>
