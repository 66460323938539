<template>
  <div id="app">
<!--    <scale-box>-->
    <header_menu></header_menu>
      <router-view/>
    <tail_menu></tail_menu>
<!--    </scale-box>-->
  </div>
</template>
<script>
import header_menu from "@/components/header_menu";
import tail_menu from "@/components/tail_menu";
// import scaleBox from "@/views/scaleBox.vue";
export default {
  components:{
    header_menu,
    tail_menu,
    // scaleBox
  }
}
</script>
<style lang="scss">
@import url('@/assets/css/common.scss');
</style>
